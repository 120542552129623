class UIService {
  private _headerInverted: boolean;
  private _headerStickyClass: string;
  constructor() {
    this._headerInverted = false;
    this._headerStickyClass = '';
  }

  get headerInverted() {
    return this._headerInverted;
  }

  set headerInverted(val: boolean) {
    this._headerInverted = val;
  }

  get headerStickyClass() {
    return this._headerStickyClass;
  }

  set headerStickyClass(val: string) {
    this._headerStickyClass = val;
  }
}

const uiService = new UIService();

export default uiService;
