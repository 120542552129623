import * as React from 'react';
import Helmet from 'react-helmet';
import { ISitemap } from '../../../types/ISitemap';
import FxMobileNav from '../../mobile-nav';
import FxFooter from './footer';
import FxHeader from './header';
import visoryFavicon from '../../../assets/img/visory/mstile-144x144.png';
import sdtrFavicon from '../../../assets/img/sdtr-favicon.png';

export interface ILayoutProps {
  sitemap: ISitemap;
}

export default class FxLayout extends React.Component<any, any> {
  lastScrollY = 0;
  ticking = false;
  layout: any;

  constructor(props: ILayoutProps) {
    super(props);

    this.state = {
      showMobileNav: false,
      sticky: false,
    };
    this.layout = React.createRef();

    this.toggleMobileNav = this.toggleMobileNav.bind(this);
    this.listenToScroll = this.listenToScroll.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll);
  }

  listenToScroll = () => {
    if (document) {
      const canvas = document.querySelector('.fx-l-canvas');

      if (canvas) {
        const winScroll = canvas.scrollTop;

        if (winScroll > 100) {
          this.setState({
            sticky: true,
          });
        } else {
          this.setState({
            sticky: false,
          });
        }
      }
    }
  };

  toggleMobileNav() {
    this.setState({ showMobileNav: !this.state.showMobileNav });
  }

  render() {
    const favicon = process.env.TENANT === 'sigma' ? visoryFavicon : sdtrFavicon;
    return (
      <div className="fx-l" onScroll={this.listenToScroll}>
        <Helmet>
          <meta
            name="google-site-verification"
            content="google-site-verification=aYxNS22c0G_OFBNmPC2EnNyOervM2iiyjKnmB8_nwMs"
          />
          <meta name="facebook-domain-verification" content="2371nxaui3xk23wf08ak8i5t7ynslc" />
          <meta name="viewport" content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover" />
          <link rel="icon" href={favicon} type="image/png" />
        </Helmet>
        <div className="fx-l-overflow">
          <div className={`fx-l-canvas ${this.state.showMobileNav === true ? 'fx-l-canvas--open' : ''}`}>
            {this.state.sticky}
            <FxHeader
              sticky={this.state.sticky}
              toggleMobileNav={this.toggleMobileNav}
              isVisible={this.state.showMobileNav}
              sitemap={this.props.sitemap}
            />
            <main className="fx-l-main">{this.props.children}</main>
            <FxFooter sitemap={this.props.sitemap} />
          </div>
        </div>
        <FxMobileNav isVisible={this.state.showMobileNav} sitemap={this.props.sitemap} />
      </div>
    );
  }
}
