import { Link } from 'gatsby';
import * as React from 'react';
import { Component } from 'react';
import { ILayoutProps } from './shared/layout/layout';

export default class MobileNav extends Component<any> {
  constructor(props: ILayoutProps) {
    super(props);
  }

  render() {
    const { isVisible } = this.props;
    const siteMap = this.props.sitemap && this.props.sitemap.childNodes;
    const isSDTR = process.env.TENANT === 'sdtr';

    const loginTitle = 'LOGIN';
    const productLink = isSDTR ? '/book-now' : '/pricing';
    const productTitle = isSDTR ? 'BOOK NOW' : 'START FREE TRIAL';
    return (
      <div className={`fx-l-nav fx-l-nav--uppercase ${isVisible ? 'fx-l-nav--visible' : ''}`}>
        <nav className="fx-l-nav-content">

          <Link className="fx-l-nav-link"
            activeClassName="fx-l-nav-link--active"
            partiallyActive={true}
            to="/"
          >
            Home
          </Link>

          {siteMap && siteMap.map((link: any) => link && link.visibleInMainNav === true && (
            <Link
              key={link.slug}
              className="fx-l-nav-link"
              activeClassName="fx-l-nav-link--active"
              partiallyActive={true}
              to={link.path}
              onClick={this.props.toggleHeaderState}
            >
              {link.displayName}
            </Link>
          ))}
          <hr className="fx-l-nav-link-divider"/>
            <Link
              className="fx-l-nav-link"
              to={productLink}
            >
              {productTitle}
            </Link>

        </nav>
      </div>
    );
  }
}
