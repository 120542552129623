import { Link } from 'gatsby';
import * as React from 'react';
import uiService from '../ui-service';
import { ILayoutProps } from './layout';

export default class FxHeader extends React.Component<any> {
  constructor(props: ILayoutProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.props.toggleMobileNav();
  }

  render() {
    const { isVisible } = this.props;
    const siteMap = this.props.sitemap && this.props.sitemap.childNodes;
    const isSDTR = process.env.TENANT === 'sdtr';
    const isVisory = process.env.TENANT === 'sigma';

    const loginTitle = 'LOGIN';
    const productLink = isSDTR ? '/book-now' : `${process.env.APP_URL}/register/signup`;
    const productTitle = 'BOOK NOW';

    return (
      <header
        className={`fx-l-header ${uiService.headerInverted ? 'fx-l-header--light' : 'fx-l-header--dark'} ${
          this.props.sticky ? uiService.headerStickyClass : ''
        }`}
      >
        <div className="fx-l-header-container">
          <button
            className={`fx-l-header-toggle ${isVisible ? 'fx-l-header-toggle--close' : ''}`}
            onClick={this.toggle}
          >
            <span />
            <span />
            <span />
          </button>
          <nav className="fx-l-header-links">
            {isSDTR && (
              <Link className="fx-l-header-brand fx-l-header-brand--large" to="/">
                <svg
                  width="181"
                  height="31"
                  className="fx-l-header-brand-logo fx-l-header-brand-logo--large"
                  viewBox="0 0 181 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M179.139 22.9202C179.139 23.0027 179.22 23.0027 179.301 22.9202L180.919 20.1995C180.919 20.1995 180.919 20.117 180.919 20.0346L180.838 19.9521H179.705H179.624C179.624 19.9521 179.543 19.9521 179.543 20.0346C179.543 20.0346 179.543 20.2819 179.463 20.5293C179.382 20.6942 178.734 22.0133 178.734 22.0133V22.0958L179.139 22.9202Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M181 27.5372L179.22 24.5692C179.22 24.4867 179.139 24.4867 179.139 24.5692L178.653 25.3936C178.573 25.4761 178.573 25.5585 178.653 25.5585L179.786 27.6197L179.867 27.7021H180.919C181 27.7021 181 27.6197 181 27.5372Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M178.734 23.9096C178.734 23.8271 178.815 23.7447 178.734 23.6623C178.411 23.0027 176.793 20.0346 176.793 20.0346L176.712 19.9521H175.498C175.417 19.9521 175.417 20.0346 175.417 20.0346L177.521 23.6623C177.521 23.6623 177.521 23.6623 177.521 23.7447L175.336 27.4548C175.336 27.5373 175.336 27.5373 175.336 27.6197L175.417 27.7021H176.55C176.631 27.7021 176.631 27.7021 176.631 27.6197L178.734 23.9096Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M151.63 21.1064C152.196 21.1064 152.601 20.6117 152.601 20.1171C152.601 20.0346 152.601 20.0346 152.52 20.0346H147.746C147.665 20.0346 147.665 20.0346 147.665 20.1171C147.746 20.3644 147.827 20.7766 147.827 21.1889V26.9601C147.827 27.125 147.746 27.6197 147.746 27.6197V27.7022H148.879C148.96 27.7022 148.96 27.6197 148.96 27.6197V24.2394H151.711C151.792 24.2394 151.872 24.1569 151.872 24.0745L152.034 23.25C152.034 23.1676 152.034 23.0851 151.953 23.0851H148.96V21.1889H151.63V21.1064Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M154.906 20.0346H153.773C153.692 20.0346 153.692 20.0346 153.692 20.1171C153.773 20.3644 153.854 20.7766 153.854 21.1889V27.6197C153.854 27.7022 153.935 27.7022 153.935 27.7022H154.906C154.987 27.7022 154.987 27.6197 154.987 27.6197V20.1995C155.027 20.0346 154.906 20.0346 154.906 20.0346Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M168.944 23.0027C168.944 22.7554 168.944 22.4256 168.863 22.1782C168.782 21.9309 168.782 21.6835 168.62 21.4362C168.539 21.1889 168.378 20.9415 168.135 20.7766C167.892 20.5293 167.569 20.3644 167.245 20.1995C166.921 20.1171 166.517 20.0346 166.193 20.0346H163.442C163.361 20.0346 163.361 20.0346 163.361 20.1171C163.442 20.3644 163.523 20.7766 163.523 21.1889V27.5373C163.523 27.6197 163.604 27.6197 163.604 27.6197H166.112C166.517 27.6197 166.84 27.5373 167.164 27.4548C167.488 27.3724 167.811 27.125 168.054 26.8777C168.297 26.6304 168.459 26.4655 168.539 26.2181C168.62 25.9708 168.701 25.7234 168.782 25.3937C168.863 25.1463 168.863 24.8165 168.863 24.5692C168.863 24.3218 168.863 23.9921 168.863 23.7447C168.944 23.4974 168.944 23.25 168.944 23.0027ZM167.649 25.1463C167.649 25.5585 167.488 25.8059 167.326 26.0532C167.002 26.383 166.598 26.5479 166.112 26.5479H164.898C164.818 26.5479 164.818 26.4655 164.818 26.4655V21.2713C164.818 21.1889 164.898 21.1889 164.898 21.1889H166.112C166.598 21.1889 167.083 21.3538 167.326 21.6835C167.488 21.9309 167.649 22.1782 167.649 22.508C167.649 22.8378 167.73 23.3325 167.73 23.8272C167.73 24.3218 167.73 24.7341 167.649 25.1463Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M174.446 26.5479H171.209V24.1569H173.718C173.799 24.1569 173.88 24.0745 173.88 23.992L174.041 23.1676C174.041 23.0851 174.041 23.0027 173.961 23.0027H171.209V21.1064H173.637C174.122 21.1064 174.608 20.6942 174.608 20.117C174.608 20.0346 174.608 19.9521 174.446 19.9521H169.915C169.834 19.9521 169.834 19.9521 169.834 20.0346C169.915 20.2819 169.996 20.6942 169.996 21.1064V27.4548C169.996 27.5373 170.077 27.5373 170.077 27.5373H174.203C174.284 27.5373 174.284 27.5373 174.284 27.4548L174.446 26.5479C174.527 26.6303 174.446 26.5479 174.446 26.5479Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M162.067 20.0346H160.934C160.934 20.0346 160.853 20.0346 160.853 20.1171C160.853 20.1171 160.934 20.5293 160.934 20.8591V23.7447V24.4867V25.1463L157.455 20.4468L157.293 20.1995C157.212 20.1171 157.131 20.0346 156.969 20.0346H156.322C156.241 20.0346 156.241 20.0346 156.241 20.1171C156.322 20.3644 156.403 20.7766 156.403 21.1889V27.5373C156.403 27.6197 156.484 27.6197 156.484 27.6197H157.455C157.536 27.6197 157.536 27.5373 157.536 27.5373V23.1676V22.7554L160.772 27.2075C160.772 27.2899 160.853 27.2899 160.853 27.3724L160.934 27.5373C161.015 27.6197 161.096 27.6197 161.177 27.6197H161.824C161.905 27.6197 161.905 27.5373 161.905 27.5373V20.1171C162.148 20.0346 162.148 20.0346 162.067 20.0346Z"
                    fill="#1A1F21"
                  />
                  <path
                    d="M161.986 31H147.907C147.664 31 147.502 30.8351 147.502 30.5878C147.502 30.3404 147.664 30.1755 147.907 30.1755H161.986C162.228 30.1755 162.39 30.3404 162.39 30.5878C162.39 30.8351 162.188 31 161.986 31Z"
                    fill="#D43F27"
                  />
                  <path
                    d="M104.457 22.1782H106.156C107.37 22.1782 108.179 22.5905 108.179 23.8272C108.179 25.0639 107.289 25.5585 106.156 25.5585H105.428V27.5373H104.457V22.1782ZM106.076 24.8165C106.885 24.8165 107.208 24.4867 107.208 23.8272C107.208 23.1676 106.804 22.9202 105.995 22.9202H105.347V24.8165H106.076Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M108.746 25.5585C108.746 24.2394 109.636 23.4149 110.607 23.4149C111.578 23.4149 112.468 24.1569 112.468 25.5585C112.468 26.8777 111.578 27.7022 110.607 27.7022C109.636 27.7022 108.746 26.8777 108.746 25.5585ZM111.578 25.5585C111.578 24.7341 111.254 24.2394 110.607 24.2394C109.959 24.2394 109.636 24.8165 109.636 25.5585C109.636 26.3006 109.959 26.8777 110.607 26.8777C111.254 26.8777 111.578 26.383 111.578 25.5585Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M113.034 23.4974H114.005L114.49 25.5585C114.571 25.9708 114.652 26.3006 114.733 26.7128C114.814 26.3006 114.895 25.8883 114.976 25.5585L115.461 23.4974H116.27L116.837 25.5585C116.918 25.9708 116.999 26.3006 117.08 26.7128C117.16 26.3006 117.241 25.9708 117.322 25.5585L117.808 23.4974H118.698L117.727 27.5373H116.594L116.109 25.641C116.028 25.2288 115.947 24.899 115.866 24.4867C115.785 24.899 115.704 25.3112 115.623 25.641L115.138 27.5373H114.086L113.034 23.4974Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M119.183 25.5585C119.183 24.2394 120.073 23.4149 121.044 23.4149C122.096 23.4149 122.663 24.2394 122.663 25.3112C122.663 25.4761 122.663 25.641 122.663 25.8059H120.154C120.235 26.5479 120.721 26.9601 121.287 26.9601C121.611 26.9601 121.934 26.8777 122.177 26.6303L122.501 27.2075C122.096 27.4548 121.611 27.6197 121.125 27.6197C120.073 27.7022 119.183 26.8777 119.183 25.5585ZM121.853 25.1463C121.853 24.4867 121.611 24.1569 121.044 24.1569C120.559 24.1569 120.154 24.4867 120.073 25.1463H121.853Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M123.633 23.4974H124.362L124.443 24.2394C124.685 23.7447 125.171 23.4149 125.575 23.4149C125.818 23.4149 125.899 23.4149 126.061 23.4974L125.899 24.3218C125.737 24.3218 125.656 24.2394 125.494 24.2394C125.171 24.2394 124.766 24.4867 124.523 25.0639V27.5373H123.552V23.4974H123.633Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M126.303 25.5585C126.303 24.2394 127.193 23.4149 128.164 23.4149C129.216 23.4149 129.783 24.2394 129.783 25.3112C129.783 25.4761 129.783 25.641 129.783 25.8059H127.274C127.355 26.5479 127.841 26.9601 128.407 26.9601C128.731 26.9601 129.054 26.8777 129.297 26.6303L129.621 27.2075C129.216 27.4548 128.731 27.6197 128.245 27.6197C127.193 27.7022 126.303 26.8777 126.303 25.5585ZM128.974 25.1463C128.974 24.4867 128.731 24.1569 128.164 24.1569C127.679 24.1569 127.274 24.4867 127.193 25.1463H128.974Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M130.592 25.5585C130.592 24.2394 131.401 23.4149 132.291 23.4149C132.776 23.4149 133.019 23.5798 133.343 23.8272V23.1676V21.6835H134.314V27.5373H133.586L133.505 27.125C133.181 27.4548 132.776 27.7022 132.372 27.7022C131.239 27.7022 130.592 26.8777 130.592 25.5585ZM133.262 26.4654V24.5692C133.019 24.3218 132.696 24.2394 132.453 24.2394C131.967 24.2394 131.563 24.7341 131.563 25.5585C131.563 26.4654 131.886 26.8777 132.453 26.8777C132.776 26.8777 133.019 26.7952 133.262 26.4654Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M137.874 27.125L137.793 27.5373H137.065V21.6835H138.036V23.1676V23.8271C138.359 23.4974 138.764 23.3325 139.168 23.3325C140.139 23.3325 140.706 24.1569 140.706 25.3936C140.706 26.7952 139.897 27.6197 139.007 27.6197C138.602 27.7021 138.197 27.4548 137.874 27.125ZM139.654 25.4761C139.654 24.6516 139.411 24.1569 138.764 24.1569C138.44 24.1569 138.197 24.3218 137.874 24.6516V26.5479C138.117 26.7952 138.44 26.8777 138.683 26.8777C139.249 26.8777 139.654 26.383 139.654 25.4761Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M141.353 29.1862L141.515 28.4442C141.596 28.4442 141.677 28.5267 141.838 28.5267C142.243 28.5267 142.486 28.1969 142.648 27.8671L142.728 27.6197L141.191 23.6623H142.162L142.809 25.641C142.89 25.9708 143.052 26.383 143.133 26.7953C143.214 26.383 143.295 26.0532 143.457 25.641L144.023 23.6623H144.913L143.457 27.8671C143.133 28.774 142.728 29.3511 141.838 29.3511C141.677 29.2687 141.515 29.1862 141.353 29.1862Z"
                    fill="#1B1F22"
                  />
                  <path
                    d="M0 12.4495L1.29459 10.883C2.18462 11.7899 3.3983 12.3671 4.61198 12.3671C6.0684 12.3671 6.7966 11.7075 6.7966 10.7181C6.7966 9.64631 5.98748 9.31652 4.85472 8.82184L3.15557 8.07982C1.94189 7.58514 0.647296 6.51333 0.647296 4.6995C0.647296 2.63833 2.42736 1.07184 4.85472 1.07184C6.31113 1.07184 7.76755 1.73141 8.73849 2.72077L7.60572 4.20482C6.7966 3.54524 5.98748 3.13301 4.85472 3.13301C3.64104 3.13301 2.91283 3.71014 2.91283 4.61705C2.91283 5.60641 3.88377 6.01865 4.93563 6.43088L6.63478 7.1729C8.17211 7.83248 9.14305 8.73939 9.14305 10.6357C9.14305 12.6968 7.4439 14.4282 4.61198 14.4282C2.83192 14.3458 1.21368 13.6862 0 12.4495Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M10.5996 11.4601C10.5996 9.39893 12.2988 8.32713 16.1825 7.91489C16.1825 6.84308 15.778 6.01862 14.4834 6.01862C13.5124 6.01862 12.6224 6.43085 11.8133 7.00798L11.0042 5.52393C12.056 4.86436 13.3506 4.28723 14.8879 4.28723C17.2344 4.28723 18.4481 5.77127 18.4481 8.49202V14.1809H16.668L16.5062 13.109H16.4253C15.6161 13.8511 14.6452 14.4282 13.5124 14.4282C11.8133 14.3457 10.5996 13.1915 10.5996 11.4601ZM16.1825 11.5426V9.39893C13.5934 9.72872 12.7842 10.3883 12.7842 11.3777C12.7842 12.2021 13.3506 12.6144 14.1597 12.6144C14.8879 12.5319 15.5352 12.2021 16.1825 11.5426Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M20.0662 4.45211H21.8462L22.008 5.77126H22.089C22.8981 4.94679 23.7881 4.20477 25.0018 4.20477C26.3773 4.20477 27.1864 4.86435 27.591 5.93616C28.481 4.94679 29.4519 4.20477 30.6656 4.20477C32.6884 4.20477 33.5785 5.60637 33.5785 7.99733V14.0159H31.3938V8.24467C31.3938 6.67818 30.9084 6.10105 29.9374 6.10105C29.371 6.10105 28.7237 6.51328 27.9955 7.33775V14.0984H25.8109V8.32711C25.8109 6.76062 25.3254 6.1835 24.3545 6.1835C23.7881 6.1835 23.1408 6.59573 22.4126 7.4202V14.1808H20.228V4.45211H20.0662Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M35.4395 9.31649C35.4395 6.18351 37.5432 4.28723 39.8087 4.28723C42.3979 4.28723 43.6925 6.18351 43.6925 8.90425C43.6925 9.31649 43.6116 9.72872 43.6116 9.97606H37.6241C37.7859 11.7074 38.9187 12.6968 40.3751 12.6968C41.1842 12.6968 41.9124 12.4495 42.5597 12.0372L43.2879 13.4388C42.3979 14.0984 41.2651 14.5106 40.0514 14.5106C37.4623 14.3457 35.4395 12.4495 35.4395 9.31649ZM41.8315 8.32713C41.8315 6.84308 41.1842 5.93617 39.8896 5.93617C38.7568 5.93617 37.7859 6.76064 37.6241 8.32713H41.8315Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M49.9226 1.23669H53.3209C57.2047 1.23669 59.4702 3.38031 59.4702 7.5851C59.4702 11.8723 57.2047 14.0984 53.4018 14.0984H49.9226V1.23669ZM53.0782 12.2846C55.5864 12.2846 57.0429 10.8005 57.0429 7.66755C57.0429 4.53457 55.5864 3.13297 53.0782 3.13297H52.1072V12.2846H53.0782Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M60.8457 11.4601C60.8457 9.39893 62.5449 8.32713 66.4286 7.91489C66.4286 6.84308 66.0241 6.01862 64.7295 6.01862C63.7585 6.01862 62.8685 6.43085 62.0594 7.00798L61.2503 5.52393C62.3021 4.86436 63.5967 4.28723 65.134 4.28723C67.4805 4.28723 68.6942 5.77127 68.6942 8.49202V14.1809H66.9141L66.7523 13.109H66.6714C65.8622 13.8511 64.8913 14.4282 63.7585 14.4282C61.9785 14.3457 60.8457 13.1915 60.8457 11.4601ZM66.3477 11.5426V9.39893C63.7585 9.72872 62.9494 10.3883 62.9494 11.3777C62.9494 12.2021 63.5158 12.6144 64.3249 12.6144C65.0531 12.5319 65.7004 12.2021 66.3477 11.5426Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M70.3934 17.8909L70.798 16.1595C70.9598 16.242 71.2834 16.3244 71.4453 16.3244C72.4162 16.3244 72.9826 15.6649 73.3062 14.6755L73.4681 14.0984L69.7461 4.53455H72.0116L73.6299 9.23401C73.9535 10.0585 74.1963 11.0478 74.439 11.9548H74.5199C74.7626 11.0478 75.0054 10.1409 75.2481 9.23401L76.6236 4.53455H78.7273L75.329 14.5931C74.5199 16.7367 73.549 18.1383 71.4453 18.1383C71.0407 18.0558 70.717 17.9734 70.3934 17.8909Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M87.0612 3.13297H83.4202V1.23669H92.9678V3.13297H89.3267V14.0984H87.0612V3.13297Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M92.3206 11.4601C92.3206 9.39893 94.0197 8.32713 97.9035 7.91489C97.9035 6.84308 97.4989 6.01862 96.2043 6.01862C95.2334 6.01862 94.3434 6.43085 93.5342 7.00798L92.7251 5.52393C93.777 4.86436 95.0716 4.28723 96.6089 4.28723C98.9553 4.28723 100.169 5.77127 100.169 8.49202V14.1809H98.2271L98.0653 13.109H97.9844C97.1753 13.8511 96.2043 14.4282 95.0716 14.4282C93.4533 14.3457 92.3206 13.1915 92.3206 11.4601ZM97.8226 11.5426V9.39893C95.2334 9.72872 94.4243 10.3883 94.4243 11.3777C94.4243 12.2021 94.9907 12.6144 95.7998 12.6144C96.6089 12.5319 97.1753 12.2021 97.8226 11.5426Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M104.619 9.06917L101.787 4.45215H104.215L105.267 6.26598C105.509 6.76066 105.833 7.33779 106.157 7.91491H106.237C106.48 7.42023 106.723 6.84311 106.966 6.26598L107.856 4.45215H110.121L107.289 9.31651L110.283 14.0984H107.856L106.723 12.2021C106.399 11.625 106.076 11.0479 105.752 10.4708H105.671C105.428 11.0479 105.105 11.625 104.862 12.2021L103.891 14.0984H101.625L104.619 9.06917Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M116.109 1.23669H120.397C122.986 1.23669 125.009 2.14361 125.009 5.02925C125.009 7.74999 122.986 9.06914 120.397 9.06914H118.374V14.0984H116.109V1.23669ZM120.073 7.25531C121.772 7.25531 122.743 6.51329 122.743 5.02925C122.743 3.54521 121.772 3.05052 120.073 3.05052H118.293V7.25531H120.073ZM119.912 8.49201L121.53 7.17286L125.494 14.1808H122.986L119.912 8.49201Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M126.385 9.31649C126.385 6.18351 128.488 4.28723 130.754 4.28723C133.343 4.28723 134.638 6.18351 134.638 8.90425C134.638 9.31649 134.557 9.72872 134.557 9.97606H128.569C128.731 11.7074 129.864 12.6968 131.32 12.6968C132.129 12.6968 132.857 12.4495 133.505 12.0372L134.233 13.4388C133.343 14.0984 132.21 14.5106 130.997 14.5106C128.488 14.3457 126.385 12.4495 126.385 9.31649ZM132.777 8.32713C132.777 6.84308 132.129 5.93617 130.835 5.93617C129.702 5.93617 128.731 6.76064 128.569 8.32713H132.777Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M135.689 4.53457L137.065 4.45213H141.029V6.26596H135.689V4.53457ZM136.984 3.46277C136.984 1.48404 137.874 0 140.139 0C140.787 0 141.434 0.164894 141.839 0.329787L141.434 1.97872C141.11 1.81383 140.706 1.73138 140.382 1.73138C139.573 1.73138 139.168 2.22606 139.168 3.38032V14.0984H136.984V3.46277Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M142.567 10.4708V4.45215H144.752V10.2234C144.752 11.7899 145.237 12.367 146.208 12.367C147.017 12.367 147.583 11.9548 148.312 11.0479V4.45215H150.496V14.0984H148.716L148.554 12.6968H148.473C147.664 13.6862 146.693 14.3458 145.399 14.3458C143.457 14.3458 142.567 12.9442 142.567 10.4708Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M152.883 4.45211H154.663L154.825 5.77126H154.906C155.715 4.94679 156.686 4.20477 157.981 4.20477C160.003 4.20477 160.893 5.60637 160.893 7.99733V14.0159H158.709V8.24467C158.709 6.67818 158.223 6.10105 157.252 6.10105C156.443 6.10105 155.877 6.51328 155.068 7.33775V14.0984H152.883V4.45211Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M162.957 9.31648C162.957 6.1835 164.898 4.28722 167.002 4.28722C168.054 4.28722 168.782 4.69945 169.51 5.35903L169.43 3.79254V0.329773H171.614V14.1808H169.834L169.672 13.109H169.591C168.863 13.8511 167.892 14.4282 166.921 14.4282C164.494 14.3457 162.957 12.4495 162.957 9.31648ZM169.349 11.3776V6.92552C168.701 6.34839 168.054 6.10105 167.407 6.10105C166.193 6.10105 165.222 7.25531 165.222 9.23403C165.222 11.2952 166.031 12.4495 167.407 12.4495C168.135 12.4495 168.782 12.1197 169.349 11.3776Z"
                    fill="#D43E27"
                  />
                  <path
                    d="M173.111 13.0266L174.163 11.625C174.972 12.2846 175.781 12.6968 176.752 12.6968C177.804 12.6968 178.289 12.2021 178.289 11.5426C178.289 10.7181 177.238 10.3883 176.267 9.97606C175.053 9.48138 173.597 8.73936 173.597 7.17287C173.597 5.52393 174.891 4.28723 177.076 4.28723C178.37 4.28723 179.422 4.86436 180.231 5.44149L179.179 6.84308C178.532 6.3484 177.885 6.01861 177.076 6.01861C176.105 6.01861 175.7 6.5133 175.7 7.09042C175.7 7.83244 176.671 8.16223 177.642 8.49202C178.937 8.9867 180.393 9.64627 180.393 11.3777C180.393 13.0266 179.099 14.3457 176.752 14.3457C175.498 14.3457 174.122 13.7686 173.111 13.0266Z"
                    fill="#D43E27"
                  />
                </svg>
              </Link>
            )}
            {isVisory && (
              <Link className="fx-l-header-brand" to="/">
                <svg
                  className="fx-l-header-brand-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  width="145"
                  height="47"
                  viewBox="0 0 145 47"
                  fill="none"
                >
                  <g clipPath="url(#clip0)">
                    <path
                      d="M42.1862 10.5993C41.5133 10.1998 40.5479 10 39.2899 10H33V11.541H33.5266C34.9894 11.541 35.984 11.9691 36.4521 12.7967C36.9495 13.6243 37.1835 14.9084 37.1835 16.6492V34H44V16.6207C44 14.8799 43.8537 13.5386 43.5904 12.5969C43.3271 11.6837 42.859 10.9988 42.1862 10.5993Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M40.4074 7.36625C41.2259 7.36625 41.875 7.11224 42.4112 6.60422C42.9475 6.0962 43.2015 5.41884 43.2015 4.54392C43.2015 3.47144 42.8628 2.5683 42.2137 1.80627C41.5646 1.07247 40.6332 0.705566 39.4196 0.705566C38.6011 0.705566 37.952 0.959575 37.4157 1.46759C36.8795 1.97561 36.6255 2.65297 36.6255 3.52789C36.6255 4.60037 36.9642 5.50351 37.6133 6.26554C38.2624 6.99934 39.1938 7.36625 40.4074 7.36625Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M61.2076 20.3207L58.1877 19.0507C56.9741 18.5145 56.071 17.9782 55.4783 17.4138C54.8856 16.8493 54.6033 16.1719 54.6033 15.3252C54.6033 14.281 54.9703 13.4343 55.6758 12.8134C56.3814 12.1925 57.4539 11.882 58.9215 11.882C60.8407 11.882 62.3365 12.4182 63.409 13.4625C64.4815 14.535 65.1871 15.9179 65.4693 17.696H67.2474C67.2474 16.0026 67.1909 14.7043 67.0498 13.8012C66.9087 12.898 66.57 12.1925 66.0338 11.6844C64.5379 10.3015 61.8285 9.62415 57.9055 9.62415C55.0267 9.62415 52.7124 10.2168 50.9626 11.4022C49.1845 12.5876 48.3096 14.2245 48.3096 16.3695C48.3096 17.95 48.8458 19.3047 49.9183 20.4054C50.9908 21.5061 52.6277 22.5504 54.7727 23.4817L57.3974 24.5824C58.9779 25.288 60.1351 25.9372 60.8689 26.5016C61.6027 27.0661 61.9414 27.7999 61.9414 28.6748C61.9414 30.9609 60.248 32.118 56.8894 32.118C54.9703 32.118 53.4744 31.5818 52.4019 30.5375C51.3295 29.4651 50.6239 28.0821 50.3416 26.3041H48.5636C48.5636 27.9692 48.62 29.2393 48.7611 30.1706C48.9023 31.102 49.2409 31.8076 49.7772 32.3156C51.273 33.6985 53.9824 34.3759 57.9055 34.3759C61.01 34.3759 63.4937 33.7832 65.4128 32.6261C67.3038 31.4689 68.2634 29.8037 68.2634 27.6588C68.2634 25.9372 67.7271 24.5542 66.6264 23.4817C65.4975 22.4093 63.6912 21.365 61.2076 20.3207Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M90.1363 11.2328C88.3018 10.1321 86.1568 9.59583 83.7014 9.59583C81.246 9.59583 79.101 10.1321 77.2665 11.2328C75.432 12.3335 74.0208 13.8011 73.033 15.692C72.0452 17.5548 71.5654 19.6715 71.5654 21.9858C71.5654 24.3001 72.0452 26.4169 73.033 28.2796C74.0208 30.1423 75.4038 31.6382 77.2665 32.7389C79.101 33.8396 81.246 34.3758 83.7014 34.3758C86.1568 34.3758 88.3018 33.8396 90.1363 32.7389C91.9708 31.6382 93.382 30.1705 94.3698 28.2796C95.3576 26.4169 95.8374 24.3001 95.8374 21.9858C95.8374 19.6715 95.3576 17.5548 94.3698 15.692C93.3538 13.8293 91.9708 12.3335 90.1363 11.2328ZM87.6244 29.3239C86.7213 31.1866 85.3948 32.0897 83.6732 32.0897C81.9516 32.0897 80.6533 31.1584 79.7219 29.3239C78.8188 27.4611 78.3672 25.0339 78.3672 21.9858C78.3672 18.9659 78.8188 16.5105 79.7219 14.6478C80.6251 12.785 81.9516 11.8537 83.6732 11.8537C85.3948 11.8537 86.6931 12.785 87.6244 14.6478C88.5276 16.5105 88.9791 18.9377 88.9791 21.9858C88.9791 25.0057 88.5276 27.4611 87.6244 29.3239Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M142.286 10.5168L136.057 27.0837L130.171 14.1345C129.571 12.8712 128.657 11.8663 127.514 11.1198C126.343 10.3733 125.029 10 123.514 10H119V11.0336C121.057 11.3208 122.629 12.6702 123.771 15.1395L132.971 35.4102L131.886 38.1952C131.171 40.0902 130.229 41.3823 129.057 42.1001C127.886 42.8179 126.371 43.1911 124.514 43.1911V45H126.571C128.486 45 130.057 44.5119 131.343 43.5357C132.6 42.5595 133.714 40.7219 134.657 38.0517L145 10.5168H142.286Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M30.4594 0.502463L19.9061 25.0394L12.0051 6.33662C10.9442 3.76847 9.57614 2.06568 7.90102 1.22824C6.22589 0.418719 4.29949 0 2.09391 0L0 0.111658V1.11658C1.03299 1.22824 1.9264 1.67488 2.6802 2.45649C3.43401 3.21018 4.18782 4.52217 4.94162 6.36453L16.5838 34H18.5939L33 0.502463H30.4594Z"
                      fill="#DF0074"
                    />
                    <path
                      d="M116.262 10.9767C115.355 10.3349 114.163 10 112.716 10C110.277 10 108.149 10.7814 106.305 12.3442C105.936 11.6744 105.426 11.2 104.745 10.9209C104.092 10.6419 103.184 10.5023 102.021 10.5023H97V12.0093H97.5106C98.9291 12.0093 99.8652 12.4279 100.319 13.2372C100.773 14.0465 101 15.3023 101 17.0047V34H107.61V17.2C107.61 16.6977 107.582 16.1395 107.496 15.5535L107.383 14.8C108.433 13.7674 109.936 13.2372 111.894 13.2372C112.887 13.2372 113.738 13.5721 114.475 14.2419C114.645 14.4093 114.816 14.5488 114.957 14.6884L117 11.6186C116.801 11.3953 116.546 11.1721 116.262 10.9767Z"
                      fill="#DF0074"
                    />
                  </g>
                  <defs>
                    <clipPath>
                      <rect width="145" height="47" className="a" />
                    </clipPath>
                  </defs>
                </svg>
              </Link>
            )}
            {siteMap &&
              siteMap.map(
                (link: any) =>
                  link &&
                  link.visibleInMainNav === true && (
                    <Link
                      className="fx-l-header-link fx-l-header-link--uppercase"
                      key={`${new Date().getMilliseconds()}-${Math.random()}`}
                      activeClassName="fx-l-header-link--active"
                      partiallyActive={true}
                      to={link.path}
                      onClick={this.props.toggleHeaderState}
                    >
                      {link.displayName}
                    </Link>
                  )
              )}
          </nav>

          <div className="fx-l-header-actions">
            <Link
              className="fx-l-header-action fx-l-header-action--primary fx-btn fx-btn--primary-outlined"
              to={productLink}
            >
              {productTitle}
            </Link>
          </div>
        </div>
      </header>
    );
  }
}
